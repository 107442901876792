"use client"
import { ArrowRightOutlined, MenuOutlined, PhoneFilled, WhatsAppOutlined } from '@ant-design/icons'
import { Button, Dropdown, Space } from 'antd'
import Link from 'next/link';
import React, { useEffect, useState } from 'react'


const Header = () => {
    const items = [
		 {
          key: '0',
          label: (
            <Link className="link"   href="/">
              Home
            </Link>
          ),
			 
        },
		  {
          type: 'divider',
        },
        {
          key: '1',
          label: (
            <Link className="link"   href="/properties-for-sale">
              Properties For Sale
            </Link>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: '2',
          label: (
            <Link className="link"   href="/properties-for-rent">
              Properties For Rent
            </Link>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: '3',
          label: (
            <Link className="link"   href="/land-for-sale">
             Land For Sale
            </Link>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: '5',
          label: (
            <Link className="link"   href="/blogs-by-gutton-kenya">
             Blogs
            </Link>
          ),
        },
        {
          type: 'divider',
        },
		 {
          key: '6',
          label: (
            <Link className="link"   href="/property-management">
            Property Management
            </Link>
          ),
        },
      ];
      const items__ =  [
        {
          key: '1',
          label: (
            <Link   href="/properties-for-sale">
              Properties For Sale
            </Link>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: '2',
          label: (
            <Link   href="/properties-for-rent">
              Properties For Rent
            </Link>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: '3',
          label: (
            <Link   href="land-for-sale">
             Land For Sale
            </Link>
          ),
        },

      ];
      const [color,setColor] = useState("transparent")
      useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 20) {
            setColor('white');
          } else {
            setColor('transparent');
          }
          console.log('Scroll position:', window.scrollY);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <div className='header' style={{background:color,transition:'2s'}}>
     <div>
      <Link href="/"> <img  src="/logo.png" style={{width:100}}/></Link>
     </div>
     <div className="disapear">
        <Space size="large">
        <Link href="/"><Button size='middle' shape='rounded' ghost={color === "white" ? false :true}>Home</Button></Link>
			<Link href="/about-guton-kenya-ltd"> <Button size='middle' shape='rounded'   ghost={color === "white" ? false :true}>About Us</Button></Link>
				 <Link href="/property-management"> <Button size='middle' shape='rounded'   ghost={color === "white" ? false :true}>Property Management</Button></Link>
        <Dropdown 
        menu={{
          items:[
            {
              key: '1',
              label: (
              <Space>
                  <Link  style={{color:'black'}}  href="/properties-for-sale">
                  Properties For Sale
                </Link>
                <ArrowRightOutlined style={{fontSize:10}} />
              </Space>
              ),
            },
            {
              type: 'divider',
            },
            {
              key: '2',
              label: (
           <Space>
                 <Link  style={{color:'black'}}  href="/properties-for-rent">
                  Properties For Rent
                </Link>
                <ArrowRightOutlined style={{fontSize:10}} />
           </Space>
              ),
            },
            {
              type: 'divider',
            },
            {
              key: '3',
              label: (
            <Space>
                  <Link  style={{color:'black'}}  href="land-for-sale">
                 Land For Sale
                </Link>
                <ArrowRightOutlined style={{fontSize:10}} />
            </Space>
                
              ),
            },
    
          ]
        }}
        placement="bottom"
        arrow
      
      >
        <Button size='middle' shape='rounded'   ghost={color === "white" ? false :true}>Our Properties</Button>
      </Dropdown>
       
			    <Link href="/blogs-by-gutton-kenya"><Button size='middle' shape='rounded'   ghost={color === "white" ? false :true}>Blogs</Button></Link>
        </Space>
     </div>

     <div className='show' style={{marginRight:0}}>
    <Dropdown
        menu={{
          items,
        }}
        placement="bottomLeft"
        arrow
      >
       <Button  type="text" shape="circle" icon={<MenuOutlined />} size={"large"} className="menu"/>
      </Dropdown>
     </div>
      <div className='disapear'>
      <Space size="large">
     <a href={`https://api.whatsapp.com/send?phone=2540115855772&text=Hello Guton Kenya. I am intrested in Buying A Property`}> <Button shape="circle" icon={<WhatsAppOutlined />} size="large" /></a>
      </Space>
      </div>
   
    </div>
  )
}

export default Header