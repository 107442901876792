import(/* webpackMode: "eager" */ "/home/gutonkenya/htdocs/gutonkenya.co.ke/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/gutonkenya/htdocs/gutonkenya.co.ke/components/footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/gutonkenya/htdocs/gutonkenya.co.ke/components/header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/gutonkenya/htdocs/gutonkenya.co.ke/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/home/gutonkenya/htdocs/gutonkenya.co.ke/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/gutonkenya/htdocs/gutonkenya.co.ke/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
