"use client"
import { AimOutlined, FacebookFilled, InstagramFilled, LinkedinFilled, PhoneOutlined, TwitterCircleFilled, YoutubeFilled,DownloadOutlined } from '@ant-design/icons'
import { Col, Divider, Row, Space, Typography ,Button} from 'antd'
import Link from 'next/link'
import React from 'react'
import App__ from './form'

const Footer = () => {
  return (
    <div style={{background:'white',padding:20}} >
    <Divider />
      <Row  gutter={[20,20]} justify="space-between" style={{padding:20}}>
        <Col xl={8} lg={8} md={8} sm={24} xs={24} style={{display:'flex',flexDirection:'column',gap:20,}}>
        <div style={{display:'flex',flexDirection:'column',gap:20,alignItems:'center',justifyContent:'center',height:"100%"}}>
         <img  src="/logo.png" alt='Guton Kenya ltd Logo' style={{width:80}}/>
         <Space>
          <span style={{fontWeight:'bold'}}>
          Solar House, 2nd floor, Harambee Avenue
          </span>
        </Space>
        <Space>
          <span style={{fontWeight:'bold'}}>
          Office: 0740545665
          </span>

        </Space>
        <Space>
          <span style={{fontWeight:'bold'}}>
          Mobile: +254 115855772 
          </span>

        </Space>
         </div>
        </Col>
       
      
        <Col xl={8} lg={8} md={8} sm={24} xs={24} style={{display:'flex',flexDirection:'column',gap:20,alignItems:'center',justifyContent:'end'}}>
        <Typography.Title level={5} style={{fontWeight:'bolder',textAlign:"center"}}>
         FOLLOW US ON OUR SOCIAL MEDIA PLATFORMS
        </Typography.Title>
        <a href="/p.pdf " style={{color:'black'}} download>
          <Button type='dashed' icon={<DownloadOutlined />} size='large' color='blue'>
          Download Our Flier For More Information
          </Button>
        </a>
<Link style={{fontWeight:'bolder',color:'black'}} href="/privacy-policy">
          Privacy Policy
        </Link>
        <Space size="large">
          <p style={{fontSize:20}}> <a style={{color:'black'}} href="https://www.facebook.com/GutonKenyaLtd"><FacebookFilled /></a></p>
         <p style={{fontSize:20}}><a style={{color:'black'}} href="https://www.instagram.com/guton_kenya_ltd/"> <InstagramFilled /></a></p>
         <p style={{fontSize:20}}><a style={{color:'black'}} href="https://www.linkedin.com/in/guton-kenya-limited-235711230/"><LinkedinFilled /></a></p>
         <p style={{fontSize:20}}><a style={{color:'black'}} href="https://mobile.twitter.com/_GutonKenyaLTD"> <TwitterCircleFilled /></a></p>
        </Space>
        </Col>
       
      </Row>
      <Divider />
    </div>
  )
}

export default Footer