"use client"
import { Button, Col, Input, Row, Typography, notification } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'

const FormInput = () => {
    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const submit = () => {
      axios.post('https://gutonkenya.co.ke/send',{
        url:window.location.href,
        user:{
          fname:name,
          lname:"Am subscribing to you News Letter",
          email:email,
          phone:"N/A",
          message:"Keep Me Updated."
        },
       
      })
      .then( ss => {
        notification.success({
          message: 'Message sent',
          description:"Thank You For Contacting Guton Kenya Ltd.",
          onClick: () => {
              console.log('Notification Clicked!');
          },
          });
      } )
      .catch( ss => {
        notification.error({
          message: 'Sorry,Please Try Sending Again',
          description:"Please check your Internet and try agin",
          onClick: () => {
              console.log('Notification Clicked!');
          },
          });
      })
    }
  return (
    <section>
    <div style={{padding:20,minHeight:300,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',gap:20}}>
      <Typography.Title>
      Receive Exclusive Off-market Listings in Your Inbox
      </Typography.Title>
      <Typography.Text>
      Are you interested in buying a home? Look no further than working with the Guton Kenya.
      </Typography.Text>
      <Row gutter={[20,20]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Input value={name} onChange={(e) => setName(e.currentTarget.value)} size='large' name='Name' placeholder='Enter your name'/>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Input  value={email} onChange={(e) => setEmail(e.currentTarget.value)} size='large' name='Email' placeholder='Enter your email address'/>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
         <Button disabled={name.length === 0  || email.length === 0 ? true :false } onClick={submit} size='large'>Submit</Button>
        </Col>
      </Row>
    </div>
    </section>
  )
}

export default FormInput